import {ReactNode} from "react";
import Coins from "../Assets/coins.png";
import {styled} from "@chakra-ui/react";

const GroupCardContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh / 2 - 30px)",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    padding: "30px",
    cursor: "none",
  }
})

const CardTitle = styled("h2", {
  baseStyle: {
    fontSize: "24px",
    fontWeight: "800",
    fontFamily: "MedievalSharp",
    color: "black",
    userSelect: "none",
  }
})

const CardDescription = styled("p", {
  baseStyle: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "MedievalSharp",
    color: "black",
    userSelect: "none",
  }
})

const CardPoints = styled("p", {
  baseStyle: {
    fontSize: "22px",
    fontWeight: "500",
    fontFamily: "MedievalSharp",
    userSelect: "none",
  }
})

interface GroupCardProps {
  name: string;
  description: string;
  icon: ReactNode;
  points: string;
  group: "RED" | "BLUE" | "GREEN" | "YELLOW";
}

function GroupCard({
                     name,
                     description,
                     icon,
                     points,
                   }: GroupCardProps) {

  return (
    <GroupCardContent>
      {icon}
      <CardTitle>{name}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <CardPoints>{points}</CardPoints>
        {/*<img
          src={Coins}
          alt="Coins"
          style={{
            height: "25px",
            marginLeft: "5px",
          }}
        />*/}
      </div>
    </GroupCardContent>
  )
}

export default GroupCard;