/* eslint-disable no-param-reassign */
const URL_Server = process.env.VITE_BACKEND_HOST || "https://backend.teklyon.eu";

const Api = {
  get(url: string, params = {}) {
    // eslint-disable-next-line no-use-before-define
    return fetch(`${URL_Server}/${url}${paramsForGet(params)}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.ok) {
          return Promise.resolve(data);
        }

        if (response.status === 403) {
          // Django exceptions are formatted { detail: string }
          data = {message: data?.detail};
        }

        return Promise.reject(data);
      })
    );
  },
  post(url: string, params = {}) {
    return fetch(`${URL_Server}/${url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) =>
      response.json().then((data) => {
        if (response.ok) {
          return Promise.resolve(data);
        }

        if (response.status === 403) {
          // Django exceptions are formatted { detail: string }
          data = {message: data?.detail};
        }

        return Promise.reject(data);
      })
    );
  },
  patch(url: string, params = {}) {
    return fetch(`${URL_Server}/${url}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) =>
      response.json().then((data) => {
        if (response.ok) {
          return Promise.resolve(data);
        }

        if (response.status === 403) {
          // Django exceptions are formatted { detail: string }
          data = {message: data?.detail};
        }

        return Promise.reject(data);
      })
    );
  },
  put(url: string, params = {}) {
    return fetch(`${URL_Server}/${url}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) =>
      response.json().then((data) => {
        if (response.ok) {
          return Promise.resolve(data);
        }

        if (response.status === 403) {
          // Django exceptions are formatted { detail: string }
          data = {message: data?.detail};
        }

        return Promise.reject(data);
      })
    );
  },
  delete(url: string, params = {}) {
    return fetch(`${URL_Server}/${url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) => {
        response.json().then((data) => {
          if (response.ok) {
            return Promise.resolve(data);
          }

          if (response.status === 204) {
            return Promise.resolve(data);
          }

          if (response.status === 403) {
            // Django exceptions are formatted { detail: string }
            data = {message: data?.detail};
          }

          return Promise.reject(data);
        }).catch((error) => {
          if (response.ok) {
            return Promise.resolve(error);
          }
        });
      }
    )
  },
};

interface IParam {
  [param: string]: string | number | Object;
}

function addItemToParams(isStart: boolean, key: string, value: any) {
  return isStart
    ? `?${key}=${encodeURIComponent(value)}`
    : `&${key}=${encodeURIComponent(value)}`;
}

function isStartParams(params: string) {
  return params === "";
}

function paramsForGet(params: IParam) {
  let paramsInString = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-loop-func
      value.forEach((v) => {
        paramsInString = `${paramsInString}${addItemToParams(
          isStartParams(paramsInString),
          key,
          v
        )}`;
      });
    } else {
      paramsInString = `${paramsInString}${addItemToParams(
        isStartParams(paramsInString),
        key,
        value
      )}`;
    }
  }
  return paramsInString;
}

export default Api;