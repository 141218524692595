import Api from "./Api";

export default class CoinServices {

  static getListCoin = () => {
      return Api.get(`list/`);
  }

  static info = () => {
      return Api.get(`info/`);
  }
}