import GroupCard from "../Components/GroupCard.tsx";
import {styled} from "@chakra-ui/react";
import Scoreboard from "../Components/Scoreboard.tsx";
import Shield from "../Assets/ShieldGlobal.png";
import ShieldRed from "../Assets/ShieldRed.png";
import ShieldBlue from "../Assets/ShieldBlue.png";
import ShieldGreen from "../Assets/ShieldGreen.png";
import ShieldYellow from "../Assets/ShieldYellow.png";
import {useEffect, useState} from "react";
import CoinServices from "../Services/CoinServices.ts";

const HomeContent = styled("div", {
  baseStyle: {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
  }
})

const Groups = styled("div", {
  baseStyle: {
    display: "flex",
    flexWrap: "wrap",
    //width: "calc(100% - 400px)",
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "relative",
    "@media (max-width: 900px)": {
      width: "100%",
      overflowY: "scroll",
    },
    cursor: "none",
  }
})

const GroupOne = styled("div", {
  baseStyle: {
    backgroundColor: "#E74C3C",
    //width: "calc((100vw - 400px) / 2)",
    width: "calc(100vw / 2)",
    height: "calc(100vh / 2)",
    minWidth: "380px",

    "@media (max-width: 900px)": {
      width: "100%",
      minHeight: "380px",
    }
  }
});

const GroupTwo = styled("div", {
  baseStyle: {
    backgroundColor: "#5DADE2",
    //width: "calc((100vw - 400px) / 2)",
    width: "calc(100vw / 2)",
    height: "calc(100vh / 2)",
    minWidth: "380px",

    "@media (max-width: 900px)": {
      width: "100%",
      minHeight: "380px",
    }
  }
});

const GroupThree = styled("div", {
  baseStyle: {
    backgroundColor: "#58D68D",
    //width: "calc((100vw - 400px) / 2)",
    width: "calc(100vw / 2)",
    height: "calc(100vh / 2)",
    minWidth: "380px",

    "@media (max-width: 900px)": {
      width: "100%",
      minHeight: "380px",
    }
  }
});

const GroupFour = styled("div", {
  baseStyle: {
    backgroundColor: "#F1C40F",
    //width: "calc((100vw - 400px) / 2)",
    width: "calc(100vw / 2)",
    height: "calc(100vh / 2)",
    minWidth: "380px",

    "@media (max-width: 900px)": {
      width: "100%",
      minHeight: "380px",
    }
  }
});

const ShieldCenter = styled("div", {
  baseStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    "@media (max-width: 900px)": {
      display: "none",
    }
  }
});

function Home() {

  const [groups, setGroups] = useState<{
    RED: number,
    BLUE: number,
    GREEN: number,
    YELLOW: number,
  }>({
    RED: 0,
    BLUE: 0,
    GREEN: 0,
    YELLOW: 0,
  })

  useEffect(() => {
    CoinServices.info().then((res: any) => {
      setGroups(res)
    })

    const interval = setInterval(() => {
      CoinServices.info().then((res: any) => {
        setGroups(res)
      })
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  return (
    <HomeContent>
      <Groups>
        <GroupOne>
          <GroupCard
            name="Groupe 1"
            description="Salle 101a"
            icon={<img
              src={ShieldRed}
              alt="Shield"
              style={{
                height: "150px",
              }}
            />}
            points={"Classement final: #1"}
          />
        </GroupOne>
        <GroupTwo>
          <GroupCard
            name="Groupe 2"
            description="Salle 101b"
            icon={<img
              src={ShieldBlue}
              alt="Shield"
              style={{
                height: "150px",
              }}
            />}
            points={"Classement final: #3"}
          />
        </GroupTwo>
        <GroupThree>
          <GroupCard
            name="Groupe 3"
            description="Salle 102a"
            icon={<img
              src={ShieldGreen}
              alt="Shield"
              style={{
                height: "150px",
              }}
            />}
            points={"Classement final: #4"}
          />
        </GroupThree>
        <GroupFour>
          <GroupCard
            name="Groupe 4"
            description="Salle 102b"
            icon={<img
              src={ShieldYellow}
              alt="Shield"
              style={{
                height: "150px",
              }}
            />}
            points={"Classement final: #2"}
          />
        </GroupFour>
        <ShieldCenter>
          <img
            src={Shield}
            alt="Shield"
            style={{
              height: "250px",
              userSelect: "none",
            }}
            />
        </ShieldCenter>
      </Groups>
      {/*<Scoreboard/>*/}
    </HomeContent>
  )
}

export default Home