import Home from "./Views/Home.tsx";
import {ChakraProvider} from "@chakra-ui/react";

function App() {

  return (
    <ChakraProvider>
      <Home/>
    </ChakraProvider>
  )
}

export default App
